<template>
  <div style="margin: 20px">
    <h3>版本号修改</h3>
    <div style="display: flex">
      <el-input
        type="text"
        v-model="form.version.value"
        clearable
        style="width: 200px; margin-right: 10px"
      />
      <el-button type="primary" @click="handleSave(1)">保存</el-button>
    </div>
    <h3>强制更新版本号修改</h3>
    <div style="display: flex">
      <el-input
        type="text"
        v-model="forma.force_version.value"
        clearable
        style="width: 200px; margin-right: 10px"
      />
      <el-button type="primary" @click="handleSave(2)">保存</el-button>
    </div>
    <h3>苹果版本号修改</h3>
    <div style="display: flex">
      <el-input
        type="text"
        v-model="form1.ios_version.value"
        clearable
        style="width: 200px; margin-right: 10px"
      />
      <el-button type="primary" @click="handleSave(3)">保存</el-button>
    </div>
    <h3>苹果强制更新版本号修改</h3>
    <div style="display: flex">
      <el-input
        type="text"
        v-model="form1a.ios_force_version.value"
        clearable
        style="width: 200px; margin-right: 10px"
      />
      <el-button type="primary" @click="handleSave(4)">保存</el-button>
    </div>
    
    <h3>安卓正在审核的版本号</h3>
    <div>
        <el-input
        type="text"
        v-model="formandroid.examine_version.value"
        clearable
        style="width: 200px; margin-right: 10px"
      />
      <el-button type="primary" @click="handleSave(5)">保存</el-button>

      <!-- <el-radio-group v-model="examine_a" size="small" @change="changeA">
        <el-radio :label="1" border>审核中</el-radio>
        <el-radio :label="2" border>审核完成</el-radio>
      </el-radio-group>
      <span style="margin-left: 20px">A</span> -->
    </div>

      <!-- <el-input
        type="text"
        v-model="form1a.ios_force_version.value"
        clearable
        style="width: 200px; margin-right: 10px; margin-top:20px"
      />
      <el-button type="primary">保存</el-button> -->
    <!-- <div style="margin-top: 50px">
      <el-radio-group v-model="examine_a" size="small" @change="changeB">
        <el-radio :label="1" border>审核中</el-radio>
        <el-radio :label="2" border>审核完成</el-radio>
      </el-radio-group>
      <span style="margin-left: 20px">B</span>
    </div> -->

    <!-- <h3>IOS</h3>
    <div>
      <el-radio-group v-model="ios_examine_a" size="small" @change="changeiosA">
        <el-radio :label="1" border>审核中</el-radio>
        <el-radio :label="2" border>审核完成</el-radio>
      </el-radio-group>
      <span style="margin-left: 20px">A</span>
    </div>
    <div style="margin-top: 50px">
      <el-radio-group v-model="ios_examine_b" size="small" @change="changeiosB">
        <el-radio :label="1" border>审核中</el-radio>
        <el-radio :label="2" border>审核完成</el-radio>
      </el-radio-group>
      <span style="margin-left: 20px">B</span>
    </div> -->
    <h3>苹果正在审核的版本号</h3>
    <div>
        <el-input
        type="text"
        v-model="formios.ios_examine_version.value"
        clearable
        style="width: 200px; margin-right: 10px"
      />
      <el-button type="primary" @click="handleSave(6)">保存</el-button>

      <!-- <el-radio-group v-model="examine_a" size="small" @change="changeA">
        <el-radio :label="1" border>审核中</el-radio>
        <el-radio :label="2" border>审核完成</el-radio>
      </el-radio-group>
      <span style="margin-left: 20px">A</span> -->
    </div>
    
    
  </div>

  
</template>

<script>
import request from "@/utils/request";
export default {
  data() {
    return {
      a_id: "",
      examine_b: "",
      b_id: "",
      ios_examine_a: "",
      ios_examine_b: "",
      ios_a_id: "",
      ios_b_id: "",
      formandroid: {
        examine_version: {
          value: "1.0.0",
        },
      },
      formios: {
        ios_examine_version: {
          value: "1.0.0",
        },
      },
      form: {
        version: {
          value: "1.0.0",
        },
      },
      forma: {
        force_version: {
          value:"1.0.0"
        },
      },
      form1:{
         ios_version:{
            value: "1.0.0",
         }
      },
      form1a:{
         ios_force_version:{
            value: "1.0.0",
         }
       }  
      
    };
  },
  created() {
    this.getSetting();
    // this.getversion();
  },
  methods: {
    android(){
    },  
    getversion() {
      request({
        url: "/api/backend/settings/get",
        method: "get",
        params: { key: "version" },
      }).then((res) => {
        console.log(res);
        this.form.version.value = res.data.value;
      });
      request({
        url: "/api/backend/settings/get",
        method: "get",
        params: { key: "ios_version" },
      }).then((res) => {
        console.log(res);
        this.form1.ios_version.value = res.data.value;
      });
      request({
        url: "/api/backend/settings/get",
        method: "get",
        params: { key: "force_version" },
      }).then((res) => {
        console.log(res);
        this.forma.force_version.value = res.data.value;
      });
      request({
        url: "/api/backend/settings/get",
        method: "get",
        params: { key: "ios_force_version" },
      }).then((res) => {
        console.log(res);
        this.form1a.ios_force_version.value = res.data.value;
      });
       request({
        url: "/api/backend/settings/get",
        method: "get",
        params: { key: "examine_version" },
      }).then((res) => {
        console.log(res.data);
        this.formandroid.examine_version.value = res.data.value;
      });
      request({
        url: "/api/backend/settings/get",
        method: "get",
        params: { key: "ios_examine_version" },
      }).then((res) => {
        console.log(res.data);
        this.formios.ios_examine_version.value = res.data.value;
      });
    
      
    },
    
    handleSave(val) {
        console.log(val);
      request({
        url: "/api/backend/settings/store",
        method: "post",
        data: val==1?this.form:val==2?this.forma:val==3?this.form1:val==4?this.form1a:val==5?this.formandroid:this.formios,
      }).then((res) => {
        this.$message({
          type: "success",
          message: "保存成功!",
        });
        this.getSetting();
      });
    },
    getSetting() {
      request({
        url: "/api/backend/settings",
        method: "get",
        params: { prefix: "version" },
      }).then((res) => {
        if (res.data.data) {
          res.data.data.map((v) => {
            if (v.key == "version") {
              this.form.version.value = v.value;
            //   this.b_id = v.id;
            } else if (v.key == "ios_version") {
              this.form1.ios_version.value = v.value;
            //   this.ios_a_id = v.id;
            } else if (v.key == "force_version") {
              this.forma.force_version.value = v.value;
            //   this.ios_b_id = v.id;
            } else if (v.key == "ios_force_version") {
              this.form1a.ios_force_version.value = v.value;
            //   this.ios_b_id = v.id;
            } else if (v.key == "examine_version") {
              this.formandroid.examine_version.value = v.value;
            //   this.ios_b_id = v.id;
            } else if (v.key == "ios_examine_version") {
              this.formios.ios_examine_version.value = v.value;
            //   this.ios_b_id = v.id;
            }
          });
          // this.$forceUpdate()
        }
      });
    },
    changeA(e) {
      request({
        url: "/api/backend/settings/store",
        method: "post",
        data: { examine_version: { value: e } },
      }).then((res) => {
        this.$message({
          type: "success",
          message: "保存成功!",
        });
        this.getSetting();
      });
    },
    changeB(e) {
      request({
        url: "/api/backend/settings/store",
        method: "post",
        data: { examine_b: { value: e } },
      }).then((res) => {
        this.$message({
          type: "success",
          message: "保存成功!",
        });
        this.getSetting();
      });
    },
    changeiosA(e) {
      request({
        url: "/api/backend/settings/store",
        method: "post",
        data: { ios_examine_a: { value: e } },
      }).then((res) => {
        this.$message({
          type: "success",
          message: "保存成功!",
        });
        this.getSetting();
      });
    },
    changeiosB(e) {
      request({
        url: "/api/backend/settings/store",
        method: "post",
        data: { ios_examine_b: { value: e } },
      }).then((res) => {
        this.$message({
          type: "success",
          message: "保存成功!",
        });
        this.getSetting();
      });
    },
  },
};
</script>

